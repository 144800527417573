import React, { useEffect, useState } from 'react';
import { withPermissionsAndRoles } from '../../session';
import { hasUserRole } from '../../common/util';
import NotificationIcon from './notificationIcon';
import HelpIcon from './helpIcon';
import FeedbackIcon from './feedbackIcon';

function TopMenuOptions({ roles = [] }) {
  const [fetchedRoles, setFetchedRoles] = useState([]);

  useEffect(() => {
    if (roles?.length > 0) {
      setFetchedRoles(roles);
    }
  }, [roles]);

  return (
    <>
      {hasUserRole(fetchedRoles) && <>
        <FeedbackIcon />
        <NotificationIcon />
        <HelpIcon />
      </>}
    </>
  );
}

export default withPermissionsAndRoles(TopMenuOptions);
